import { makeResource } from '@codium/codium-vue'
import { http } from '@/utils'

const pagination = (pagination) => {
  if (pagination.current_page !== undefined && pagination.last_page !== undefined) {
    return pagination.current_page === pagination.last_page
  }

  return pagination.from === undefined ||
    pagination.to === undefined ||
    pagination.from === null ||
    pagination.to === null ||
    pagination.to - (pagination.from - 1) < pagination.per_page
}

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('cameras', {
  storeModule: {
    mutations: {
      SET_SELECTED_CAMERA_IDS (state, { selectedCameraIds }) {
        state.selectedCameraIds = selectedCameraIds
      }
    },
    actions: {
      async getSelectedCameraIds ({ dispatch, state, commit }, { params = {}, fresh = false }) {
        try {
          let page
          if (fresh) {
            page = 1
          } else if (state.meta) {
            if (pagination(state.meta)) {
              throw new Error('No more results.')
            }

            page = state.meta.current_page + 1
          }
          if (!page) {
            page = 1
          }
          const response = await http.get('/cameras', { params: { ...params, page } })

          if (page > 1) {
            dispatch('addCameras', response.data.data)
          } else {
            dispatch('setCameras', response.data.data)
          }
          dispatch('setMeta', response.data.meta || null)
          commit('SET_SELECTED_CAMERA_IDS', {
            selectedCameraIds: response.data.meta.selected_camera_ids,
          })
        } catch (error) {
          console.error('Error fetching settings:', error)
          throw error
        }
      }
    }
  },
  resource () {
    return {
      id: null,
      name: null,
      screen: null,
    }
  }
})
