import { makeResource } from '@codium/codium-vue'
import { storeModule as screenTags } from './settings/screen-tags'
import { storeModule as mediaOwnerTags } from './settings/media-owner-tags'
import { http } from '@/utils'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('settings', {
  modules: {
    screenTags,
    mediaOwnerTags
  },
  storeModule: {
    mutations: {
      SET_USER_SETTINGS (state, { items, total }) {
        state.settings = items
        state.total = total
      }
    },
    actions: {
      async getUserSettings ({ commit }, { page = 1 }) {
        try {
          const response = await http.get('/user/settings', {
            params: {
              page,
            }
          })
          commit('SET_USER_SETTINGS', {
            items: response.data.data,
            total: response.data.meta.total,
          })
        } catch (error) {
          console.error('Error fetching settings:', error)
          throw error
        }
      }
    }
  },
  resource () {
    return {
    }
  }
})
